import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';
import { Challenges } from '../../../editor/types/Experiments';
import {
  CHALLENGE_PAGE_ID,
  PARTICIPANT_PAGE_ID,
} from '../../../editor/app-config';
import {
  ChallengeSummary,
  MemberChallenge,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { State } from '@wix/ambassador-challenges-v1-participant/types';
import { getParticipantStateFromSummary } from '../../../components/ChallengesList/Badge/helpers/GetParticipantStateFromSummary';
import {
  getUrlParams,
  locationProviderPropsMap,
} from '../locationProviderPropsMap';
import {
  getChallengePageSectionUrl,
  getParticipantPageSectionUrl,
  isParticipantPageInstalled,
} from '../../../services/wixCodeApi';
import { buildProgramPageUrl } from './buildProgramPageUrl';
import { appendQueryStringToUrl } from '../urlHelpers';

import { getProgramSlug } from '../../../selectors/getProgramSlug';

export const isUserGoesToParticipantPage = (
  summary: ChallengeSummary,
): boolean => {
  return [
    State.COMPLETED,
    State.FAILED,
    State.JOINED,
    State.RUNNING,
    State.SUSPENDED,
  ].includes(getParticipantStateFromSummary(summary));
};

export const isParticipantPageAvailable = async (
  flowAPI: ControllerFlowAPI,
): Promise<boolean> => {
  if (flowAPI.experiments.enabled(Challenges.redirectToParticipantPage)) {
    const isParticipantPageInstalled_ = await isParticipantPageInstalled(
      flowAPI,
    );

    return !!isParticipantPageInstalled_;
  }

  return false;
};

function addProgramSlugToUrl(url: string, slug: string) {
  return `${url}/${slug}`;
}

export async function getProgramPageUrl(
  flowAPI: ControllerFlowAPI,
  isParticipant: boolean,
  participantPageAvailable: boolean,
  slug: string,
): Promise<any> {
  if (
    flowAPI.experiments.enabled('spec.programs.HandleCustomizedUrlSegments') &&
    !isParticipant
  ) {
    return buildProgramPageUrl(flowAPI, slug);
  }
  try {
    if (isParticipant && participantPageAvailable) {
      return addProgramSlugToUrl(
        (await getParticipantPageSectionUrl(flowAPI)).url,
        slug,
      );
    } else {
      return addProgramSlugToUrl(
        (await getChallengePageSectionUrl(flowAPI)).url,
        slug,
      );
    }
  } catch (error) {
    handleError({
      error,
      context: 'getSectionUrl',
    });
  }

  return null;
}
export const generateLinksToProgramForList = async (
  flowAPI: ControllerFlowAPI,
  membersPrograms: MemberChallenge[] = [],
) => {
  const linksToProgram: {
    [id: string]: string;
  } = {};
  const isParticipantPageAvailable_ = await isParticipantPageAvailable(flowAPI);

  await Promise.all(
    membersPrograms.map(async (memberProgram) => {
      const programId = memberProgram.challenge.id;
      const url = await getProgramPageUrl(
        flowAPI,
        isUserGoesToParticipantPage(memberProgram.summary),
        isParticipantPageAvailable_,
        getProgramSlug(memberProgram.challenge),
      );
      linksToProgram[programId] = appendQueryStringToUrl({
        url,
        query: `programId=${programId}`,
      });
    }),
  );

  return linksToProgram;
};

export const getParticipantPageDeepLink = (flowApi: ControllerFlowAPI) => {
  const urlParams = getUrlParams(flowApi);
  if (
    urlParams.navigationType === 'section' ||
    urlParams.navigationType === 'step'
  ) {
    return `${urlParams.navigationType}/${urlParams.navigationId}`;
  }
  if (urlParams.navigationType === 'description') {
    return `${urlParams.navigationId}`;
  }
};

export const navigateToProgramPage = async (
  flowAPI: ControllerFlowAPI,
  options: {
    challengeId: string;
    isParticipant: boolean;
    queryParams?: any;
    deepLink?: string;
  },
): Promise<void> => {
  return locationProviderPropsMap(flowAPI).goToPage({
    challengeId: options.challengeId,
    pageId:
      (await isParticipantPageAvailable(flowAPI)) && options.isParticipant
        ? PARTICIPANT_PAGE_ID
        : CHALLENGE_PAGE_ID,
    deepLink: options.deepLink,
    queryParams: { ...options.queryParams } || null,
  });
};
